$(document).ready(function() {
    // console.log('cookies experience script');
    var donationParameter = 'maf-donation';
    
    // Helper function to get a parameter from the URL.
    function getUrlParameter(param) {
        var url = window.location.search.substring(1),
            urlVars = url.split('&'),
            parameterName,
            i;
        for (i = 0; i < urlVars.length; i++) {
            parameterName = urlVars[i].split('=');
            if (parameterName[0] === param) {
                return parameterName[1] === undefined ? true : decodeURIComponent(parameterName[1]);
            }
        }
    }

    var domain = '';
    if (location.host.indexOf('medicalert.org') > -1) {
        domain = ".medicalert.org";
    } else{
        domain = window.location.hostname;        
    }
        

    var start = function() {        
        // If we are not in the cart return
        if (window.location.href.indexOf("Cart-Show") === -1) {

            //If Coupon NOT in URL
            if(window.location.href.indexOf(donationParameter) === -1){
                // console.log('no donation parameter');
                return;
            }
            //If Coupon IS in URL
            try {
                Cookies.set(donationParameter, getUrlParameter(donationParameter), { domain: domain, expires: 1 });
            } catch (e) {
                // console.log(e);
            }
            return;
        }                
        Cookies.remove("maf-donation",{ domain: domain });
        return;
    };        

    var waitForDeps = setInterval(function() {
        if (typeof $ != 'undefined' && typeof Cookies != 'undefined') {
            start();                
            clearInterval(waitForDeps);
        }
    }, 10);


});
